<template>
    <div class="blind">
        <h5header :name="$t('blind.desc4')" :right="false" />
        <div class="blindbg">
            <div class="rightBtn">
                <div class="btn-info" @click="$router.push('/blind/record')">
                    <div>{{ $t('blind.desc66') }}</div>
                </div>
                <div class="btn-info" @click="showRules = true">
                    <div>{{ $t('blind.desc67') }}</div>
                </div>
                <div class="btn-info" @click="showPrize = true">
                    <div>{{ $t('blind.desc68') }}</div>
                </div>
            </div>
            <div class="blind-con flex">
                <div class="blind-left">
                    <div class="rules-title">{{ $t('blind.desc8') }}</div>
                    <div class="rules-desc">
                        <div>{{ $t('blind.desc9') }}</div>
                        <div>{{ $t('blind.desc10') }}{{ configInfo.startTime }} - {{ configInfo.endTime }} (GST)</div>
                        <div v-html="$t('blind.desc11', { n1: configInfo.drawCount })"></div>
                        <div>{{ $t('blind.desc12') }}</div>
                        <div>{{ $t('blind.desc13') }}</div>
                        <div>{{ $t('blind.desc14') }}</div>
                        <div>{{ $t('blind.desc15', { n1: configInfo.drawCount }) }}</div>
                        <div v-if="configInfo.list != undefined">{{ $t('blind.desc16') }}
                            <span v-for="(item, index) in configInfo.list" :key="item.id">{{ item.amount }}
                                {{ configInfo.coinName }} <strong
                                    v-if="index != configInfo.list.length - 1">/</strong></span>
                            <span> {{ $t('blind.desc19', { n1: configInfo.list.length }) }}</span>
                        </div>
                        <div>{{ $t('blind.desc17') }}{{ accountTypeName }}</div>
                        <div>{{ $t('blind.desc18') }}</div>
                    </div>
                </div>
                <div class="blind-center">
                    <div class="bilnd-top flex">
                        <img src="../../public/images/bilnd/lineimg.png" alt="" />
                        <div>{{ $t('blind.desc20') }}</div>
                        <img src="../../public/images/bilnd/lineimg1.png" alt="" />
                    </div>
                    <div class="blind-time flex" v-if="endAct == 1">
                        <div class="time-name">{{ $t('blind.desc21') }}</div>
                        <van-count-down :time="time" @finish="getconfig">
                            <template #default="timeData">
                                <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                <span class="block" v-else>{{ timeData.days }}</span>
                                <span class="colon">{{ $t('blind.desc22') }}</span>
                                <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                <span class="block" v-else>{{ timeData.hours }}</span>
                                <span class="colon">{{ $t('blind.desc23') }}</span>
                                <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                <span class="block" v-else>{{ timeData.minutes }}</span>
                                <span class="colon">{{ $t('blind.desc24') }}</span>
                                <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                <span class="block" v-else>{{ timeData.seconds }}</span>
                                <span class="colon">{{ $t('blind.desc25') }}</span>
                            </template>
                        </van-count-down>
                    </div>
                    <div class="blind-time flex" v-else-if="endAct == 0">
                        <div class="time-name">{{ $t('blind.desc70') }}</div>
                        <van-count-down :time="time" @finish="getconfig">
                            <template #default="timeData">
                                <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                <span class="block" v-else>{{ timeData.days }}</span>
                                <span class="colon">{{ $t('blind.desc22') }}</span>
                                <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                <span class="block" v-else>{{ timeData.hours }}</span>
                                <span class="colon">{{ $t('blind.desc23') }}</span>
                                <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                <span class="block" v-else>{{ timeData.minutes }}</span>
                                <span class="colon">{{ $t('blind.desc24') }}</span>
                                <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                <span class="block" v-else>{{ timeData.seconds }}</span>
                                <span class="colon">{{ $t('blind.desc25') }}</span>
                            </template>
                        </van-count-down>
                    </div>
                    <div class="blind-time flex" v-else>
                        <div class="time-name">{{ $t('blind.desc72') }}</div>
                        <div class="endtime">{{ configInfo.endTime }}(GST)</div>
                    </div>
                    <div class="blind-gift flex">
                        <div class="gift-info" v-for="(item, index) in 4" :key="index">
                            <img src="../../public/images/bilnd/gif1.gif" alt="" />
                        </div>
                    </div>
                    <div class="blind-amount">
                        <div class="amount-name">{{ $t('blind.desc26') }}</div>
                        <div class="amount-list flex">
                            <div class="list-info" v-for="item in configInfo.list" :key="item.id">
                                <div class="info-name">{{ item.name }}</div>
                                <div class="info-img">
                                    <img :src="item.url" alt="">
                                </div>
                                <div class="info-price">{{ item.amount }} {{ configInfo.coinName }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="blind-btn" v-if="endAct == 0">
                        <div class="btn-name">{{ $t('blind.desc74') }}</div>
                        <div class="tips-time">
                            {{ $t('blind.desc71') }} <span>{{ configInfo.startTime }} (GST)</span>
                        </div>
                    </div>
                    <div class="blind-btn1 flexcenter" v-else-if="endAct == 2">
                        {{ $t('blind.desc73') }}
                    </div>
                    <div class="blind-btn" @click="showBtn = true"
                        v-else-if="endAct == 1 && configInfo.drawedCount > 0 && showBilnd">
                        <div class="btn-name">{{ $t('blind.desc28') }}</div>
                        <div class="btn-time flex">
                            <div class="time-name">{{ $t('blind.desc29') }}</div>
                            <van-count-down :time="todayTime">
                                <template #default="timeData">

                                    <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                    <span class="block" v-else>{{ timeData.hours }}</span>
                                    <span class="colon">:</span>
                                    <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                    <span class="block" v-else>{{ timeData.minutes }}</span>
                                    <span class="colon">:</span>
                                    <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                    <span class="block" v-else>{{ timeData.seconds }}</span>
                                </template>
                            </van-count-down>
                        </div>
                    </div>

                    <div class="blind-btn1 flexcenter" v-else-if="endAct == 1 && configInfo.drawedCount == 0 && showBilnd">
                        {{ $t('blind.desc39') }}
                    </div>
                    <div class="blind-btn1 flexcenter" v-else>
                        {{ $t('blind.desc40') }}
                    </div>
                    <div class="blind-tips" v-if="showBilnd && endAct == 1">
                        <div v-html="$t('blind.desc30', { n1: configInfo.drawCount, n2: configInfo.drawedCount })"></div>
                        <p>{{ $t('blind.desc31') }}</p>
                    </div>
                    <div class="blind-tips1" v-else-if="!showBilnd && endAct == 1">
                        <div>{{ $t('blind.desc63') }}</div>
                        <div v-html="$t('blind.desc64')" @click="$router.push('/market')"></div>
                    </div>
                </div>
                <div class="blind-right">
                    <div class="right-user">
                        <div class="user-name">{{ $t('blind.desc32') }}</div>
                        <div class="user-info" v-if="recordInfo != null">
                            <div class="info-name">{{ recordInfo.blindName }}</div>
                            <div class="info-img">
                                <img :src="recordInfo.imageUrl" alt="" />
                            </div>
                            <div class="info-price">{{ recordInfo.price }} {{ recordInfo.prizeName }}</div>
                        </div>
                        <div class="null-data" v-if="showNull1">
                            <img src="../../public/images/no-data.png" alt="">
                            <span>{{ $t('record.desc13') }}</span>
                        </div>
                        <div class="user-btn flexcenter" @click="$router.push('/blind/record')">{{
                            $t('blind.desc33') }}</div>

                    </div>
                    <div class="right-platform" :style="{ minHeight: lang == 'en_US' ? '491px' : '430px' }">
                        <div class="platform-name">{{ $t('blind.desc34') }}</div>
                        <div class="platform-title" v-html="$t('blind.desc35', { n1: totals })"></div>
                        <div class="platform-table">
                            <div class="table-thead flex">
                                <div>{{ $t('blind.desc36') }}</div>
                                <div>{{ $t('blind.desc37') }}</div>
                                <div>{{ $t('blind.desc38') }}</div>
                            </div>
                            <div class="table-tbody"
                                :style="{ overflowY: platformList.length < 10 ? 'inherit' : 'scroll' }">
                                <div class="tbody-info flex" v-for="item in platformList" :key="item.id">
                                    <div>{{ dealAddress(item.userId) }}</div>
                                    <div>{{ item.price }} {{ item.prizeName }}</div>
                                    <div>{{ item.datetime }}</div>
                                </div>
                                <div class="null-data" v-if="showNull">
                                    <img src="../../public/images/no-data.png" alt="">
                                    <span>{{ $t('record.desc13') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- H5 -->
        <div class="h5blindbg">
            <div class="rightBtn">
                <div class="btn-info" @click="$router.push('/blind/record')">
                    <div>{{ $t('blind.desc66') }}</div>
                </div>
                <div class="btn-info" @click="showRules = true">
                    <div>{{ $t('blind.desc67') }}</div>
                </div>
                <div class="btn-info" @click="showPrize = true">
                    <div>{{ $t('blind.desc68') }}</div>
                </div>
            </div>
            <div class="topinfo"></div>
            <div class="topimg">
                <img src="../../public/images/bilnd/icon1.png" alt="" class="img1" />
                <img src="../../public/images/bilnd/icon2.png" alt="" class="img2" />
                <img src="../../public/images/bilnd/icon3.png" alt="" class="img3" />
                <img src="../../public/images/bilnd/icon4.png" alt="" class="img4" />
                <img src="../../public/images/bilnd/gift2.gif" class="img5" alt="">
            </div>
            <div class="h5con">
                <div style="height:270px;"></div>
                <div class="blind-btn" v-if="endAct == 0">
                    <div class="btn-name">{{ $t('blind.desc74') }}</div>
                    <div class="tips-time">
                        {{ $t('blind.desc71') }} <span>{{ configInfo.startTime }} (GST)</span>
                    </div>
                </div>
                <div class="blind-btn1" v-else-if="endAct == 2">
                    {{ $t('blind.desc73') }}
                </div>
                <div class="blind-btn" @click="showBtn = true"
                    v-else-if="endAct == 1 && configInfo.drawedCount > 0 && showBilnd">
                    <div class="btn-name">{{ $t('blind.desc28') }}</div>
                    <div class="btn-time flex">
                        <div class="time-name">{{ $t('blind.desc29') }}</div>
                        <van-count-down :time="todayTime">
                            <template #default="timeData">

                                <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                <span class="block" v-else>{{ timeData.hours }}</span>
                                <span class="colon">:</span>
                                <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                <span class="block" v-else>{{ timeData.minutes }}</span>
                                <span class="colon">:</span>
                                <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                <span class="block" v-else>{{ timeData.seconds }}</span>
                            </template>
                        </van-count-down>
                    </div>
                </div>
                <div class="blind-btn1" v-else-if="endAct == 1 && configInfo.drawedCount == 0 && showBilnd">
                    {{ $t('blind.desc39') }}
                </div>
                <div class="blind-btn1" v-if="!showBilnd && endAct == 1">
                    {{ $t('blind.desc40') }}
                </div>
                <div class="blind-tips" v-if="showBilnd && endAct == 1">

                    <div :class="{ 'tips1': endAct == 1 && configInfo.drawedCount == 0 && showBilnd }"
                        v-html="$t('blind.desc30', { n1: configInfo.drawCount, n2: configInfo.drawedCount })"></div>
                    <p>{{ $t('blind.desc31') }}</p>
                </div>
                <div class="blind-tips1" v-else-if="!showBilnd && endAct == 1">
                    <div>{{ $t('blind.desc63') }}</div>
                    <div v-html="$t('blind.desc64')" @click="$router.push('/market')"></div>
                </div>
                <div class="blind-list">
                    <div class="list-title flex">
                        <div>{{ $t('blind.desc65') }}</div>
                        <p class="flex" @click="$router.push('/blind/record')">{{ $t('blind.desc66') }}
                            <img src="../../public/images/bilnd/icon.png" alt="">
                        </p>
                    </div>
                    <div class="list-gift flex">
                        <div class="list-info" v-for="item in configInfo.list" :key="item.id">
                            <div class="info-name">{{ item.name }}</div>
                            <div class="info-img">
                                <img :src="item.url" alt="">
                            </div>
                            <div class="info-price">{{ item.amount }} {{ configInfo.coinName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提示 -->
        <div class="blind-fix" v-if="showBtn">
            <div class="fix-con">
                <div class="con-logo">
                    <img src="../../public/images/bilnd/logo.png" alt="" />
                </div>
                <div class="con-gift">
                    <div class="gift-name flex">
                        <img src="../../public/images/bilnd/linebg.png" alt="">
                        <div>{{ $t('blind.desc20') }}</div>
                        <img src="../../public/images/bilnd/linebg1.png" alt="">
                    </div>
                    <div class="gift-img">
                        <img src="../../public/images/bilnd/gift2.gif" alt="">
                    </div>
                </div>
                <div class="con-btn flexcenter" @click="handlerBtn" v-loading="loading">{{ $t('blind.desc41') }}</div>
            </div>
        </div>
        <div class="blind-fix" v-if="showSuccess">
            <div class="fix-con">
                <div class="con-title">{{ $t('blind.desc42') }}</div>
                <div class="con-info">
                    <div class="info-name">{{ blindInfo.blindName }}</div>
                    <div class="info-img">
                        <img :src="blindInfo.imageUrl" alt="" />
                    </div>
                    <div class="info-price">{{ blindInfo.price }} {{ blindInfo.prizeName }}</div>
                </div>
                <div class="info-btn flexcenter" @click="handleClose">{{ $t('blind.desc43') }}</div>
                <div class="info-tips">
                    <div>{{ $t('blind.desc44') }}</div>
                    <p>{{ $t('blind.desc45') }} <span @click="$router.push('/asset')">{{ $t('blind.desc46') }}</span></p>
                </div>
            </div>
        </div>
        <!-- 规则 -->
        <van-popup v-model:show="showRules" round>
            <div class="rules">
                <div class="rules-title flex">
                    <div>{{ $t('blind.desc8') }}</div>
                    <img src="../../public/images/icon_close.png" alt="" @click="showRules = false" />
                </div>
                <div class="rules-desc">
                    <div>{{ $t('blind.desc9') }}</div>
                    <div>{{ $t('blind.desc10') }}{{ configInfo.startTime }} - {{ configInfo.endTime }} (GST)</div>
                    <div v-html="$t('blind.desc11', { n1: configInfo.drawCount })"></div>
                    <div>{{ $t('blind.desc12') }}</div>
                    <div>{{ $t('blind.desc13') }}</div>
                    <div>{{ $t('blind.desc14') }}</div>
                    <div>{{ $t('blind.desc15', { n1: configInfo.drawCount }) }}</div>
                    <div v-if="configInfo.list != undefined">{{ $t('blind.desc16') }}
                        <span v-for="(item, index) in configInfo.list" :key="item.id">{{ item.amount }}
                            {{ configInfo.coinName }} <strong v-if="index != configInfo.list.length - 1">/</strong></span>
                        <span> {{ $t('blind.desc19', { n1: configInfo.list.length }) }}</span>
                    </div>
                    <div>{{ $t('blind.desc17') }}{{ accountTypeName }}</div>
                    <div>{{ $t('blind.desc18') }}</div>
                </div>
                <div class="rules-btn flexcenter" @click="showRules = false">{{ $t('blind.desc43') }}</div>
            </div>
        </van-popup>
        <!-- 平台 -->
        <van-popup v-model:show="showPrize" round position="bottom">
            <div class="prize">
                <div class="prize-title">
                    <div>{{ $t('blind.desc69') }}</div>
                    <img src="../../public/images/icon_close.png" alt="" @click="showPrize = false" />
                </div>
                <div class="prize-desc" v-html="$t('blind.desc35', { n1: totals })"></div>
                <div class="prize-table">
                    <div class="table-thead flex">
                        <div>{{ $t('blind.desc37') }}</div>
                        <div>{{ $t('blind.desc36') }}</div>

                        <div>{{ $t('blind.desc38') }}</div>
                    </div>
                    <div class="table-tbody">
                        <div class="tbody-info flex" v-for="item in platformList" :key="item.id">
                            <div>{{ item.price }} {{ item.prizeName }}</div>
                            <div>{{ dealAddress(item.userId) }}</div>

                            <div>{{ item.datetime }}</div>
                        </div>
                        <div class="null-data" v-if="showNull">
                            <img src="../../public/images/no-data.png" alt="">
                            <span>{{ $t('record.desc13') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            showPrize: false,
            showRules: false,
            time: 0,
            todayTime: 0,
            configInfo: {},
            endAct: 0,
            accountTypeName: '',
            recordInfo: null,
            platformList: [],
            totals: 0,
            showNull: false,
            showBilnd: false,
            showBtn: false,
            blindInfo: {},
            loading: false,
            showSuccess: false,
            showNull1: false,
            lang: 'en_US'
        }
    },
    mounted() {
        this.lang = localStorage.getItem('language')
        let time = new Date(), time1 = time.getTime(), endtime = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
        this.todayTime = endtime - time1;
        this.getconfig();
        this.$post(this.URL.blind.check, {}).then(res => {
            if (res.code == 0 || res.code == 70033) {
                this.showBilnd = true;

            }
        })
    },
    methods: {
        dealAddress(str) {
            str = str.toString()
            if (str.length > 4) {
                return (
                    str.substring(0, 2) +
                    "***" +
                    str.substring(str.length - 2, str.length)
                );
            } else {
                return str;
            }
        },
        getconfig() {
            this.$post(this.URL.blind.config, {}).then(res => {
                if (res.code == 0) {
                    this.configInfo = res.data;
                    let time2 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();
                    if (todayTime > this.configInfo.endTimeTimeStamp + time2) {

                        this.endAct = 2;
                    } else if (todayTime < this.configInfo.startTimeTimeStamp + time2) {
                        this.time = this.configInfo.startTimeTimeStamp + time2 - todayTime;
                        this.endAct = 0;
                    } else {
                        this.time = this.configInfo.endTimeTimeStamp + time2 - todayTime;
                        this.endAct = 1;
                    }
                    if (res.data.accountType == 1) {
                        this.accountTypeName = this.$t('new.desc7')
                    } else if (res.data.accountType == 2) {
                        this.accountTypeName = this.$t('new.desc8')
                    } else {
                        this.accountTypeName = this.$t('new.desc9')
                    }
                }
            })
            this.$post(this.URL.blind.record, {
                page: 1,
                pageSize: 1
            }).then(res => {
                if (res.code == 0) {
                    if (res.data.list != '') {
                        this.recordInfo = res.data.list[0];
                    }

                    if (res.data.page.count == 0) {
                        this.showNull1 = true;
                    } else {
                        this.showNull1 = false;
                    }
                }
            })
            this.$post(this.URL.blind.allrecord, {
                page: 1,
                pageSize: 200
            }).then(res => {
                if (res.code == 0) {
                    this.platformList = res.data.list;
                    this.totals = res.data.page.count;
                    if (this.totals != 0) {
                        this.platformList.forEach(e => {
                            let time = this.common.dateBJtoLocal(e.createTime), time1 = time.getTime(), time2 = 4 * 60 * 60 * 1000;
                            e.datetime = this.common.formatDate5(time1 + time2);
                        })
                    }
                    if (this.totals == 0) {
                        this.showNull = true;
                    } else {
                        this.showNull = false;
                    }
                }
            })
        },
        handleClose() {
            this.showSuccess = false;
        },
        handlerBtn() {
            if (this.loading) {
                return;
            }
            this.loloading = true;
            this.$post(this.URL.blind.draw, {}).then(res => {

                if (res.code == 0) {
                    this.showSuccess = true;
                    this.showBtn = false;
                    this.blindInfo = res.data;
                    this.getconfig();
                } else {
                    this.loading = false;
                    this.$message(res.message)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.blind {
    padding: 96px 0 0;

    .h5blindbg {
        display: none;
    }

    .blindbg {
        padding: 56px 0 80px;
        background: url('../../public/images/bilnd/blindbg1.png') center no-repeat;
        background-size: 100% 100%;

        .rightBtn {
            display: none;
        }

        .blind-con {
            width: 1240px;
            margin: 0 auto;

            .blind-left {
                position: relative;
                flex: 0 0 292px;
                padding: 32px 20px;
                border-radius: 12px;
                overflow: hidden;
                background: url('../../public/images/bilnd/bg.png') center no-repeat;
                background-size: 100% 100%;

                .rules-title {
                    padding: 32px 0 20px;
                    font-size: 20px;
                    color: #FFFFFF;
                }

                .rules-desc {
                    font-size: 14px;
                    color: #989898;

                    div {
                        margin-bottom: 12px;
                        line-height: 18px;
                    }

                    span {
                        &:last-child {
                            margin-right: 3px;
                        }
                    }

                }
            }

            .blind-center {
                flex: 1;
                margin: 0 20px;
                padding: 32px 16px;
                //border: 1px solid rgba(255, 255, 255, 0.20);
                border-radius: 12px;
                background: url('../../public/images/bilnd/blindbg2.png') center no-repeat;
                background-size: 100% 100%;

                .bilnd-top {

                    justify-content: center;

                    img {
                        flex: 0 0 83px;
                        width: 83px;
                        height: 17px;
                        margin-top: 1px;
                    }

                    div {
                        margin: 0 8px;
                        font-size: 20px;
                        color: #FF554F;
                        font-weight: 600;
                        line-height: 20px;
                    }
                }

                .blind-time {
                    justify-content: center;
                    margin: 20px 0;
                    font-size: 20px;
                    line-height: 20px;
                    color: #fff;

                    .endtime {
                        margin-left: 5px;
                        color: #C9FA5B;
                    }

                    .block {
                        padding: 0 3px;
                        font-size: 20px;
                        line-height: 20px;
                        color: #C9FA5B;
                    }

                    .colon {
                        font-size: 20px;
                        line-height: 20px;
                        color: #fff;
                    }
                }

                .blind-gift {
                    padding: 32px 20px;
                    border-radius: 12px;
                    background: rgba(0, 0, 0, 0.50);

                    .gift-info {
                        flex: 0 0 25%;
                        text-align: center;
                        cursor: pointer;
                        border-radius: 8px;
                        border: 2px solid rgba(0, 0, 0, 0);
                        box-sizing: border-box;

                        &:hover {
                            border: 2px solid #C9FA5B;
                            background: url('../../public/images/bilnd/bg1.png') center no-repeat;
                            background-size: 100% 100%;
                        }

                        img {
                            width: 89px;
                            height: 124px;
                        }
                    }
                }

                .blind-amount {
                    margin: 20px 0;
                    padding: 24px 20px 34px;
                    border-radius: 12px;
                    background: rgba(0, 0, 0, 0.50);

                    .amount-name {
                        font-size: 18px;
                        color: #fff;
                        text-align: center;

                    }

                    .amount-list {
                        margin-top: 20px;

                        .list-info {
                            flex: 0 0 118px;
                            margin-right: 24px;
                            text-align: center;
                            font-size: 14px;
                            color: #FFFFFF;
                            line-height: 20px;

                            &:last-child {
                                margin-right: 0;
                            }

                            .info-img {
                                margin: 16px 0;
                                height: 56px;
                                text-align: center;

                                img {
                                    width: 72px;
                                    height: 56px;
                                }
                            }

                        }
                    }

                }

                .blind-btn {
                    width: 287px;
                    height: 72px;
                    margin: 0 auto;
                    background: url('../../public/images/bilnd/btnbg1.png')center no-repeat;
                    background-size: 100% 100%;
                    cursor: pointer;

                    .btn-name {
                        padding: 15px 0 2px;
                        font-size: 15px;
                        color: #C9FA5B;
                        line-height: 19px;
                        text-align: center;
                    }

                    .tips-time {
                        margin-top: 2px;
                        text-align: center;
                        font-size: 10px;
                        color: #fff;

                        span {
                            color: #C9FA5B;
                        }
                    }

                    .btn-time {
                        justify-content: center;
                        font-size: 10px;
                        color: #FFFFFF;

                        .time-name {
                            margin-right: 2px;
                            line-height: 22px;
                        }

                        .block,
                        .colon {
                            font-size: 10px;
                            color: #FFFFFF;
                        }

                        .colon {
                            padding: 0 2px;
                        }
                    }
                }

                .blind-btn1 {
                    width: 287px;
                    height: 72px;
                    margin: 0 auto;
                    background: url('../../public/images/bilnd/btnbg3.png')center no-repeat;
                    background-size: 100% 100%;
                    cursor: pointer;
                    font-size: 15px;
                    color: rgba(255, 255, 255, 0.5);
                }

                .blind-tips {
                    margin-top: 20px;
                    text-align: center;

                    div {
                        font-size: 12px;
                        color: #FFFFFF;
                        line-height: 17px;
                    }

                    p {
                        margin-top: 8px;
                        font-size: 12px;
                        color: rgba(255, 255, 255, 0.6);
                    }
                }

                .blind-tips1 {
                    margin-top: 20px;
                    font-size: 12px;
                    color: #fff;
                    line-height: 16px;
                    text-align: center;

                }
            }

            .blind-right {
                flex: 0 0 292px;

                .right-user {
                    min-height: 290px;
                    padding: 24px 20px 40px;
                    background: url('../../public/images/bilnd/blindbg3.png') center no-repeat;
                    background-size: 100% 100%;

                    .user-name {
                        text-align: center;
                        font-size: 18px;
                        color: #989898;
                        text-align: center;
                    }

                    .user-info {
                        margin-top: 16px;
                        text-align: center;

                        .info-name,
                        .info-price {
                            font-size: 14px;
                            color: #fff;
                        }

                        .info-img {
                            margin: 16px 0;

                            img {
                                width: 72px;
                                height: 56px;
                            }
                        }
                    }

                    .user-btn {
                        width: 136px;
                        height: 40px;
                        margin: 16px auto 0;
                        background: url('../../public/images/bilnd/btnbg2.png') center no-repeat;
                        background-size: 100% 100%;
                        cursor: pointer;
                        font-size: 14px;
                        color: #FFFFFF;
                    }

                    .null-data {
                        padding: 20px 0;

                        img {
                            display: block;
                            width: 120px;
                            height: 120px;
                            margin: 0 auto 16px;
                        }

                        span {
                            display: block;
                            margin: 0 auto;
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            text-align: center;
                            color: #A1A1A1;
                        }
                    }
                }

                .right-platform {
                    margin-top: 20px;
                    padding: 24px 20px 20px;
                    min-height: 430px;
                    background: url('../../public/images/bilnd/blindbg4.png') center no-repeat;
                    background-size: 100% 100%;

                    .platform-name {
                        text-align: center;
                        font-size: 18px;
                        color: #958B8B;
                        line-height: 25px;
                    }

                    .platform-title {
                        margin: 12px 0 20px;
                        font-size: 12px;
                        color: #fff;
                        line-height: 17px;
                        text-align: center;
                    }

                    .table-thead {
                        margin-bottom: 12px;

                        div {
                            flex: 0 0 33.3%;
                            font-size: 12px;
                            color: #989393;
                            line-height: 17px;

                            &:nth-child(2) {
                                text-align: center;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }
                    }

                    .table-tbody {
                        max-height: 280px;
                        overflow-y: scroll;

                        &::-webkit-scrollbar {
                            width: 4px;
                            height: 4px;
                            background-color: rgba(255, 255, 255, .4);
                        }

                        /*定义滚动条轨道
 内阴影+圆角*/
                        &::-webkit-scrollbar-track {
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            border-radius: 10px;
                            background-color: rgba(255, 255, 255, .5);
                        }

                        /*定义滑块
     内阴影+圆角*/
                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                            background-color: rgba(0, 0, 0, .5);
                        }

                        .null-data {
                            padding: 50px 0;

                            img {
                                display: block;
                                width: 120px;
                                height: 120px;
                                margin: 0 auto 16px;
                            }

                            span {
                                display: block;
                                margin: 0 auto;
                                font-family: 'PingFang SC';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                text-align: center;
                                color: #A1A1A1;
                            }
                        }
                    }

                    .tbody-info {
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        div {
                            flex: 0 0 33.3%;
                            font-size: 12px;
                            color: #fff;
                            line-height: 17px;

                            &:nth-child(2) {
                                text-align: center;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

.blind-fix {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;

    .fix-con {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 375px;
        transform: translate(-50%, -50%);

        .con-logo {
            text-align: center;

            img {
                width: 74px;
            }
        }

        .con-gift {
            width: 100%;
            height: 375px;
            background: url('../../public/images/bilnd/bg2.png') center no-repeat;
            background-size: 100% 100%;

            .gift-name {
                justify-content: center;

                img {
                    flex: 0 0 83px;
                    width: 83px;
                    height: 17px;
                    margin-top: 1.5px;
                }

                div {
                    margin: 0 8px;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                }
            }
            .gift-img{
                margin-top: 55px;
                text-align: center;
                img{
                    width: 200px;
                    height: 200px;
                }
            }
        }

        .con-btn,
        .info-btn {
            position: relative;
            width: 287px;
            height: 51px;
            margin: -30px auto 0;
            background: url('../../public/images/bilnd/btnbg4.png') center no-repeat;
            background-size: 100% 100%;
            font-size: 15px;
            color: #C9FA5B;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                bottom: -18px;
                left: 0;
                width: 100%;
                height: 25px;
                background: url('../../public/images/bilnd/linebg2.png') center no-repeat;
                background-size: 100% 100%;
            }
        }

        .con-info {
            width: 100%;
            height: 375px;
            background: url('../../public/images/bilnd/bg3.png') center no-repeat;
            background-size: 100% 100%;

            .info-name {
                font-size: 20px;
                color: #FFFFFF;
                text-align: center;
            }

            .info-img {
                margin: 83px 0 11px;
                text-align: center;

                img {
                    width: 108px;
                    height: 108px;
                }
            }

            .info-price {
                font-size: 30px;
                color: #C9FA5B;
                text-align: center;
            }
        }

        .info-btn {
            margin-top: 0;
        }

        .info-tips {
            margin-top: 37px;
            text-align: center;
            font-size: 12px;
            color: #fff;

            span {
                color: #C9FA5B;
                cursor: pointer;
            }
        }

        .con-title {
            font-size: 20px;
            color: #FFFFFF;
            text-align: center;
        }
    }
}

.rules {
    padding-bottom: 20px;
    width: calc(100vw - 12px);

    .rules-title {
        justify-content: space-between;
        padding: 20px;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 20px;

        img {
            width: 20px;
            height: 20px;

        }
    }

    .rules-desc {
        height: 400px;
        padding: 0 20px;
        font-size: 14px;
        color: #989898;


        div {
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .rules-btn {
        margin: 20px auto 0;
        width: 320px;
        height: 48px;
        background: url('../../public/images/bilnd/btnbg7.png') center no-repeat;
        background-size: 100% 100%;
        font-size: 15px;
        color: #000000;
    }
}


.prize {
    min-height: 528px;
    background: url('../../public/images/bilnd/bg9.png') center no-repeat;
    background-size: 100% 100%;

    .prize-title {
        position: relative;
        padding: 20px 0 24px;

        div {
            padding: 0 36px;
            font-size: 18px;
            color: #FFFFFF;
            font-weight: 600;
            text-align: center;
        }

        img {
            position: absolute;
            top: 50%;
            right: 20px;
            width: 20px;
            height: 20px;
            transform: translateY(-50%);
        }
    }
    .prize-desc{
        width: calc(100% - 8px);
        padding: 8px 0;
        margin:0 auto 16px;
        font-size: 14px;
        color: #fff;
        line-height: 16px;
        text-align: center;
        border-radius: 8px;
background: rgba(201, 250, 91, 0.10);
    }
    .prize-table {
        padding: 0 16px;

        .table-thead {
            margin-bottom: 12px;
            padding: 0 16px;

            div {
                flex: 0 0 33.3%;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.7);


                &:nth-child(2) {
                    text-align: center;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }

        .table-tbody {
            max-height: 380px;
            overflow-y: scroll;

            .null-data {
                padding: 50px 0;

                img {
                    display: block;
                    width: 120px;
                    height: 120px;
                    margin: 0 auto 16px;
                }

                span {
                    display: block;
                    margin: 0 auto;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #A1A1A1;
                }
            }
        }

        .tbody-info {
            padding: 12px 16px;
            margin-bottom: 12px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            div {
                flex: 0 0 33.3%;
                font-size: 12px;
                color: #fff;
                line-height: 17px;

                &:first-child {
                    font-size: 16px;
                    color: #C9FA5B;
                    font-weight: 600;
                }

                &:nth-child(2) {
                    text-align: center;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .blind {
        padding: 88px 0 60px;

        .blindbg {
            position: relative;
            padding: 0 16px;

            .rightBtn {
                position: fixed;
                top: 100px;
                right: 12px;
                width: 60px;
                z-index: 55;
                display: block;

                .btn-info {
                    width: 60px;
                    height: 60px;
                    margin-bottom: 16px;
                    background: url('../../public/images/bilnd/bg6.png') center no-repeat;
                    background-size: 100% 100%;

                    &:nth-child(2) {
                        background: url('../../public/images/bilnd/bg7.png') center no-repeat;
                        background-size: 100% 100%;
                    }

                    &:last-child {
                        margin-bottom: 0;
                        background: url('../../public/images/bilnd/bg8.png') center no-repeat;
                        background-size: 100% 100%;
                    }

                    div {
                        padding: 44px 0 0 0;
                        font-size: 9px;
                        color: #fff;
                        text-align: center;
                        height: 14px;
                    }
                }
            }

            .blind-con {
                width: 100%;
                flex-wrap: wrap;

                .blind-center {
                    flex: 0 0 100%;
                    margin: 20px 0;
                }

                .blind-right,
                .blind-left {
                    display: none;
                }
            }
        }

    }
}

@media (max-width: 767px) {
    .blind {

        padding: 56px 0;
        background: url('../../public/images/bilnd/bg4.png') center no-repeat;
        background-size: 100% 100%;

        .blindbg {
            display: none;
        }

        .h5blindbg {
            position: relative;
            display: block;

            .rightBtn {
                position: absolute;
                top: 68px;
                right: 12px;
                width: 46px;
                z-index: 55;

                .btn-info {
                    width: 46px;
                    height: 46px;
                    margin-bottom: 16px;
                    background: url('../../public/images/bilnd/bg6.png') center no-repeat;
                    background-size: 100% 100%;

                    &:nth-child(2) {
                        background: url('../../public/images/bilnd/bg7.png') center no-repeat;
                        background-size: 100% 100%;
                    }

                    &:last-child {
                        margin-bottom: 0;
                        background: url('../../public/images/bilnd/bg8.png') center no-repeat;
                        background-size: 100% 100%;
                    }

                    div {
                        padding: 32px 0 0 0;
                        font-size: 9px;
                        color: #fff;
                        text-align: center;
                        height: 14px;
                    }
                }
            }

            .topinfo {
                width: 100%;
                height: 229px;
                background: url('../../public/images/bilnd/topbg.png') center no-repeat;
                background-size: 100% 100%;
            }

            .topimg {
                position: relative;
                position: absolute;
                top: 80px;
                left: 50%;
                width: 176px;
                height: 304px;
                transform: translateX(-50%);
                text-align: center;
                .img1{
                    position: absolute;
                    top: 0;
                    left: 16px;
                    width: 32px;
                    height: 32px;
                }
                .img2{
                    position: absolute;
                    top: 27px;
                    left: 72px;
                    width: 38px;
                    height: 38px;
                }
                .img3{
                    position: absolute;
                    top: 59px;
                    left: 0;
                    width: 63px;
                    height: 63px;
                }
                .img4{
                    position: absolute;
                    top: 65px;
                    right: 0;
                    width: 64px;
                    height: 64px;
                }
                .img5 {
                    margin-top: 104px;
                    width:145px;
                    height: 200px;
                }
            }

            .h5con {
                width: 100%;
                min-height: 423px;
                background: url('../../public/images/bilnd/topbg1.png') center no-repeat;
                background-size: 100% 100%;

                .blind-btn {
                    width: 206px;
                    height: 72px;
                    margin: 0 auto;
                    background: url('../../public/images/bilnd/btnbg5.png') center no-repeat;
                    background-size: 100% 100%;

                    .btn-name {
                        padding: 10px 0 3px;
                        font-size: 15px;
                        color: #C9FA5B;
                        text-align: center;
                        line-height: 15px;
                    }

                    .tips-time {
                        font-size: 10px;
                        color: #fff;
                        text-align: center;

                        span {
                            color: #C9FA5B
                        }
                    }

                    .btn-time {
                        justify-content: center;
                        font-size: 10px;
                        color: #FFFFFF;

                        .time-name {
                            line-height: 10px;
                        }

                        ::v-deep .van-count-down {
                            font-size: 10px !important;
                            line-height: 10px !important;
                        }

                        .colon,
                        .block {
                            font-size: 10px;
                            color: #fff;
                        }
                    }
                }

                .blind-btn1 {
                    width: 206px;
                    height: 72px;
                    margin: 0 auto;
                    background: url('../../public/images/bilnd/btnbg6.png') center no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.5);
                    text-align: center;
                    line-height: 50px;
                }

                .blind-tips {
                    max-width:100%;
                    width: max-content;
                    margin: 16px auto 0;
                    padding: 0 16px;
                    font-size: 12px;
                    color: #fff;
                    line-height: 14px;
                    text-align: center;

                    div {
                        
                        padding: 4px 8px;
                        border-top: 1px solid #C9FA5B;
                        border-bottom: 1px solid #C9FA5B;
                        background: rgba(201, 250, 91, 0.20);
                    }

                    .tips1 {
                        margin-top: 20px;
                        background: rgba(144, 144, 144, 0.5);
                    }

                    p {
                        margin-top: 8px;
                        font-size: 12px;
                        color: rgba(255, 255, 255, 0.6);
                    }
                }

                .blind-tips1 {
                    padding: 4px 8px;
                    width: max-content;
                    margin: 20px auto 0;
                    border-top: 1px solid #909090;
                    border-bottom: 1px solid #909090;
                    background: rgba(144, 144, 144, 0.20);
                    font-size: 12px;
                    color: #fff;
                    text-align: center;
                    line-height: 14px;
                }

                .blind-list {
                    width: calc(100vw - 8px);
                    border-radius: 12px;
                    margin: 32px auto 0;
                    border: 1px solid rgba(255, 255, 255, 0.20);
                    background: rgba(0, 0, 0, 0.04);

                    .list-title {
                        padding: 16px 16px 12px;

                        div {
                            flex: 1;
                            font-size: 16px;
                            color: #FFFFFF;
                            line-height: 16px;
                        }

                        p {
                            font-size: 12px;
                            color: rgba(201, 250, 91, 0.6);

                            img {
                                flex: 0 0 5px;
                                width: 5px;
                                height: 8px;
                                margin: 4px 0 0 4px;
                            }
                        }
                    }

                    .list-gift {
                        padding: 0 16px 16px;

                        .list-info {
                            flex: 0 0 70px;
                            margin-right: 12px;
                            text-align: center;
                            font-size: 9px;
                            color: #FFFFFF;
                            line-height: 20px;

                            &:last-child {
                                margin-right: 0;
                            }

                            .info-img {
                                margin: 8px 0;
                                height: 48px;
                                text-align: center;

                                img {
                                    width: 61px;
                                    height: 48px;
                                    object-fit: cover;
                                }
                            }

                        }
                    }
                }

            }

        }
    }

    .blind-fix {
        .fix-con {
            width: calc(100vw - 8px);

            .info-tips {
                margin-top: 12px;
            }
        }
    }

    .rules {
        .rules-desc {
            height: 300px;
            overflow-y: scroll;
        }

        .rules-btn {
            width: calc(100% - 10px);
        }
    }

}</style>